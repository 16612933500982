import React from "react"
import {
  container,
  container__content,
  container__content__items,
  container__content__items__item,
} from "./style/warranty.module.scss"
import Divider from "./images/divider3.png"
import Divider2 from "./images/divider4.png"
import Product from "./icons/PRODUKTPOLSKI.svg"
import Leaf from "./icons/leavesW.svg"
import Planet from "./icons/ecologyW.svg"
import { AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const Warranty: React.FC = (): JSX.Element => {
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <div className={container__content}>
          <h2>3x 100% = pewność i gwarancja jakości</h2>
          <img src={Divider} alt="divider" />
          <div className={container__content__items}>
            <div className={container__content__items__item}>
              <img src={Product} />
              <p>produkt 100% Polski</p>
            </div>
            <div className={container__content__items__item}>
              <img src={Leaf} />
              <p>produkt 100% vegański</p>
            </div>
            <div className={container__content__items__item}>
              <img src={Planet} />
              <p>produkt 100% naturalny</p>
            </div>
          </div>
          <img src={Divider2} alt="divider" />
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Warranty
