import {
  AnimatedButton,
  AnimatedLink,
  AnimatedSection,
} from "@components/shared"
import React from "react"
import {
  wrapper,
  btn,
  wrapper__info,
  wrapper__grid,
  wrapper__grid__first,
  wrapper__grid__second,
  wrapper__grid__third,
  wrapper__grid__fourth,
} from "./style/blog.module.scss"
import { main__wrapper } from "../../../styles/typography.module.scss"
const Blog = () => {
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={wrapper}>
        <h2>
          Zapraszamy także na bloga, aby dowiedzieć się więcej na temat konopii.
        </h2>
        <p className={wrapper__info}>Najczęściej czytane artykuły</p>
        <div className={wrapper__grid}>
          <div className={wrapper__grid__first}>
            <h3>Big Title</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

          <div className={wrapper__grid__second}>
            <h3>Big Title</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div className={wrapper__grid__third}>
            <h3>Big Title</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div className={wrapper__grid__fourth}>
            <h3>Big Title</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>
        <AnimatedButton className={btn}>
          Zobacz pozostale artykuły
        </AnimatedButton>
      </section>
    </AnimatedSection>
  )
}

export default Blog
