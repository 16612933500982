import React from "react"
import {
  container,
  container__content,
  container__content__eclipses,
  container__content__description,
} from "./style/about.module.scss"
import Eclipse1 from "./icons/Ellipse214.svg"
import Eclipse2 from "./icons/Ellipse215.svg"
import Divider from "./images/divideDark1.png"
import { AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const About: React.FC = (): JSX.Element => {
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <div className={container__content}>
          <h2>Krótko o nas</h2>
          <div className={container__content__eclipses}>
            <img src={Eclipse2} />
            <img src={Eclipse1} />
          </div>
          <p className={container__content__description}>
            W The Old Hemp dbamy o wiedzę. Dzielimy się z tobą aktualnymi
            informacjami i badaniami na temat konopi. W oparciu o nie, możesz
            świadomie i bezpiecznie podjąć decyzję o stosowaniu olejków CBD w
            określonych sytuacjach. W sklepie oferujemy tylko nasze olejki
            premium CBD - pełne spektrum. Aktualne badania potwierdzają
            dobroczynny wpływ wielu substancji konopnych na organizm ludzki.
            Nieustannie pilnujemy jakości naszych olejów na każdym etapie:
            produkcji, magazynowania i dystrybucji. Mając wszystko pod kontrolą
            dostarczamy zawsze świeżą partię produktu najwyższej jakości.
          </p>
          <img src={Divider} alt="" />
        </div>
      </section>
    </AnimatedSection>
  )
}

export default About
