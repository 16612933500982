import React from "react"
import {
  container,
  container__content,
  container__content__bold,
  container__content__title,
  container__content__buttons,
  container__content__buttons__light,
  container__content__buttons__dark,
} from "./style/ebook.module.scss"
import Books from "./icons/books.svg"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const Ebook: React.FC = (): JSX.Element => {
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <div className={container__content}>
          <h2 className={container__content__title}>
            Jesli chciałbyć wiedzieć jak konopia może pomóc Ci w życiu
            zapraszamy do kupna ebooka
          </h2>
          <img src={Books} alt="books" />
          <h2 className={container__content__bold}>
            "40 korzyści wynikających ze stosowania przetworów konopii w życiu
            codziennym"
          </h2>
          <div className={container__content__buttons}>
            <AnimatedButton className={container__content__buttons__dark}>
              Kup ebook
            </AnimatedButton>
            <AnimatedButton className={container__content__buttons__light}>
              Przeczytaj fragment
            </AnimatedButton>
          </div>
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Ebook
