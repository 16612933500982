import React from "react"
import {
  hero,
  hero__leaf,
  hero__content,
  hero__content__button,
} from "./style/hero.module.scss"
import Leaf from "./images/leafes-with-bottle 1.png"
import { AnimatedButton } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const Hero: React.FC = (): JSX.Element => {
  return (
    <div className={main__wrapper}>
      <section className={hero}>
        <div className={hero__content}>
          <h1>
            Każda kropla oleju The Old Hemp to bogactwo wszystkich cennych
            składników konopii.
          </h1>
          <h4>
            Nasze oleje produkujemy korzystając z tradycyjnej i naturalnej
            metody.
          </h4>
          <p>Zobacz jak pomogą Ci nasze konopie:</p>
          <AnimatedButton className={hero__content__button}>
            Zobacz
          </AnimatedButton>
        </div>
        <img className={hero__leaf} alt="hero-leaf" src={Leaf} />
      </section>
    </div>
  )
}

export default Hero
