import React from "react"
import {
  container,
  container__content,
  container__content__logo,
  container__content__divider,
} from "./style/forest.module.scss"
import divider3 from "./images/divider3.png"
import divider4 from "./images/divider4.png"
import Logo from "./icons/theoldhempLOGOtext.svg"
import { AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const Forest: React.FC = (): JSX.Element => {
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <div className={container__content}>
          <img
            src={Logo}
            alt="old-hemp-logo"
            className={container__content__logo}
          />
          <img
            src={divider3}
            className={container__content__divider}
            alt="divider-3"
          />
          <h3>Wyznajemy holistyczne podejscie do naturalnej mocy konopii</h3>
          <p>
            Nasze oleje są najbliższe naturalnemu składowi rosliny, .... z
            wynikiem .... olej CBD zachowuje całą gamę dobroczynnych składników.
            W zgodzie z naturą i najnowszymi badaniami nad olejami konopnymi.
            Teraz nowe większe objętości olejów 15 ml, 30ml i 50 ml.
          </p>
          <img
            src={divider4}
            className={container__content__divider}
            alt="divider-4"
          />
          <h3>Abyś mógł cieszyć się zdrowiem jak najdłużej.</h3>
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Forest
