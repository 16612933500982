import { useActions, CustomerCtx, useContextState } from "@components/contexted"
import React, { useState } from "react"
import Invoice from "./Invoice"
import {
  content__modal__double,
  content__modal__checkbox,
  content__modal__double__single,
} from "./style/paymentmodal.module.scss"
import Divide1 from "./images/divideDark1.png"
const OrderForm: React.FC = (): JSX.Element => {
  const [notSame, setNotSame] = useState(false)
  const { changeValue } = useActions(CustomerCtx, ["changeValue"])
  const {
    first_name,
    last_name,
    email,
    note,
    phone,
    address_1,
    city,
    postcode,
    country,
  } = useContextState(CustomerCtx, [
    "first_name",
    "last_name",
    "email",
    "note",
    "phone",
    "address_1",
    "city",
    "postcode",
    "country",
  ])
  return (
    <>
      <h1>Płatność</h1>
      <h3>Jak możemy się z Tobą skontaktować</h3>
      <label htmlFor="name">IMIĘ</label>
      <input
        placeholder="np. Jan "
        value={first_name}
        onChange={(ev) => {
          changeValue({ first_name: ev.target.value })
        }}
      />

      <label htmlFor="name">NAZWISKO</label>
      <input
        placeholder="np. Kowalski "
        value={last_name}
        onChange={(ev) => {
          changeValue({ last_name: ev.target.value })
        }}
      />

      <label htmlFor="email">EMAIL</label>
      <input
        type="email"
        placeholder="np. jan@kowalski.pl "
        value={email}
        onChange={(ev) => {
          changeValue({ email: ev.target.value })
        }}
      />

      <label htmlFor="agree" className={content__modal__checkbox}>
        <input type="checkbox" />* Zgoda na dołączenie do newslettera
      </label>

      <label htmlFor="number">NUMER TELEFONU</label>

      <input
        placeholder="Numer telefonu"
        value={phone}
        onChange={(ev) => {
          changeValue({ phone: ev.target.value })
        }}
      />

      <img src={Divide1} />

      <h3>Gdzie mamy wysłąć paczkę?</h3>

      <div className={content__modal__double}>
        <div className={content__modal__double__single}>
          <label>ULICA</label>
          <input
            placeholder="np. Miejska 4"
            value={address_1}
            onChange={(ev) => {
              changeValue({ address_1: ev.target.value })
            }}
          />
        </div>
        <div className={content__modal__double__single}>
          <label>Miasto</label>
          <input
            placeholder="np. Warszawa"
            value={city}
            onChange={(ev) => {
              changeValue({ city: ev.target.value })
            }}
          />
        </div>
      </div>

      <div className={content__modal__double}>
        <div className={content__modal__double__single}>
          <label>KOD POCZTOWY</label>
          <input
            placeholder="np. 00-001"
            value={postcode}
            onChange={(ev) => {
              changeValue({ postcode: ev.target.value })
            }}
          />
        </div>
        <div className={content__modal__double__single}>
          <label>KRAJ</label>
          <input
            placeholder={"np. Polska"}
            value={country}
            onChange={(ev) => {
              changeValue({ country: ev.target.value })
            }}
          />
        </div>
      </div>
      <label htmlFor="agree" className={content__modal__checkbox}>
        <input
          type="checkbox"
          checked={notSame}
          onChange={(e) => setNotSame(e.target.checked)}
        />
        Inne dane do faktury / Faktura na firmę
      </label>

      {notSame && <Invoice />}

      <label
        htmlFor="agree"
        className={content__modal__checkbox}
        style={{ marginTop: "80px" }}
      >
        <input type="checkbox" /> Akcepetacja regulaminu
      </label>

      <label htmlFor="agree" className={content__modal__checkbox}>
        <input type="checkbox" /> Zgoda na przetwarzanie danych
      </label>
    </>
  )
}

export default OrderForm
