import React, { useState, useEffect, useRef, useContext } from "react"

import {
  order__container,
  order__container__content,
  order__container__content__pay,
  order__container__content__buttons,
  quantity,
  btn,
  order__small,
  order__small__pay,
  order__small__modal,
  order__small__modal__content,
  space__between,
  order__small__modal__content__buttons,
  order__container__content__select,
  order__small__modal__content__select,
  bold,
} from "./style/order.module.scss"
import Icon from "./icons/Path217.svg"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import useClickOutside from "./common/useClickOutside"
import PaymentModal from "./PaymentModal"
import { main__wrapper } from "../../../styles/typography.module.scss"
const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const Order: React.FC = (props): JSX.Element => {
  const [open, setOpen] = useState(false)

  const modalRef = useRef<HTMLElement | boolean>(false)

  const [state, setState] = useState({
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variation_id: null,
    variant: null,
    sale: false,
    price: "",
    regularPrice: "",
    unit: "PLN",
    name: "",
    slug: "",
    image: null,
  })
  const { products } = props
  const onePersonProduct = products.find(
    (el) => el.name === "Pakiet dla 1 osoby 15ml"
  )
  const twoPersonsProduct = products.find((el) => el.name === "dla pary 30ml")
  const familyProduct = products.find(
    (el) => el.name === "dla całej rodziny 50ml"
  )

  const changeQty = (val) => {
    const _number = Number(val)
    if (_number <= 0 || Number.isNaN(_number)) {
      return false
    } else {
      setState({
        ...state,
        quantity: _number,
        value: _number * state.price,
        regularValue: _number * state.regularPrice,
      })
    }
  }

  const addToCart = (product) => {
    setState({
      product_id: product.productId,
      name: product.name,
      slug: product.slug,
      price: cleanPrice(product.regularPrice),
      regularPrice: cleanPrice(product.regularPrice),
      regularValue: cleanPrice(product.regularPrice),
      value: cleanPrice(product.regularPrice),
      quantity: 1,
      image: product.image,
    })
  }

  useEffect(() => {
    addToCart(onePersonProduct)
  }, [])

  return (
    <AnimatedSection className={main__wrapper}>
      <section className={order__container}>
        <h5>Znasz już nasze produkty? Skorzystaj z szybkiego zakupu</h5>
        <div className={order__container__content}>
          <label htmlFor="capacity">Pojemność:</label>
          <div className={order__container__content__select}>
            <select
              name="capacity"
              onChange={(ev) => addToCart(JSON.parse(ev.target.value))}
            >
              <option value={JSON.stringify(onePersonProduct)}>15ml </option>
              <option value={JSON.stringify(twoPersonsProduct)}>30ml</option>
              <option value={JSON.stringify(familyProduct)}>50ml</option>
            </select>
          </div>

          <div className={order__container__content__buttons}>
            <label htmlFor="quantity">Ilość:</label>
            <AnimatedButton
              className={btn}
              onClick={() => {
                if (state.quantity <= 1) {
                  return false
                } else {
                  changeQty(state.quantity - 1)
                }
              }}
            >
              -
            </AnimatedButton>
            <div className={quantity}>{state.quantity}</div>
            <AnimatedButton
              className={btn}
              onClick={() => changeQty(state.quantity + 1)}
            >
              +
            </AnimatedButton>
          </div>
          <p>
            Do zapłaty: <b className={bold}>{state.regularValue} ZŁ</b>
          </p>
          <PaymentModal
            className={order__container__content__pay}
            product={state}
          />
        </div>
        <div className={order__small}>
          <AnimatedButton
            className={order__small__pay}
            onClick={() => setOpen((prev) => !prev)}
          >
            <img src={Icon} alt="" />
          </AnimatedButton>
          <OrderWithModal close={() => setOpen(false)} open={open}>
            <h5>Znasz już nasze produkty? Skorzystaj z szybkiego zakupu</h5>

            <div className={space__between}>
              <label htmlFor="capacity">Pojemność:</label>
              <div className={order__small__modal__content__select}>
                <select
                  name="capacity"
                  onChange={(ev) => addToCart(JSON.parse(ev.target.value))}
                >
                  <option value={JSON.stringify(onePersonProduct)}>
                    15ml{" "}
                  </option>
                  <option value={JSON.stringify(twoPersonsProduct)}>
                    30ml
                  </option>
                  <option value={JSON.stringify(familyProduct)}>50ml</option>
                </select>
              </div>
            </div>
            <div className={space__between}>
              <label htmlFor="quantity">Ilość:</label>
              <div className={order__small__modal__content__buttons}>
                <AnimatedButton
                  className={btn}
                  onClick={() => {
                    if (state.quantity <= 1) {
                      return false
                    } else {
                      changeQty(state.quantity - 1)
                    }
                  }}
                >
                  -
                </AnimatedButton>
                <div className={quantity}>{state.quantity}</div>
                <AnimatedButton
                  className={btn}
                  onClick={() => changeQty(state.quantity + 1)}
                >
                  +
                </AnimatedButton>
              </div>
            </div>
            <div className={space__between}>
              <p>Do zapłaty:</p>
              <p style={{ fontWeight: 600 }}>{state.regularValue} ZŁ</p>
            </div>
          </OrderWithModal>
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Order

interface IModal {
  open: boolean
  close: () => void
}
const OrderWithModal: React.FC<IModal> = ({
  close,
  open,
  children,
}): JSX.Element | null => {
  const ref = useRef<HTMLElement | null>(null)

  useClickOutside(ref, close)

  useEffect(() => {
    return () => {
      close()
    }
  }, [])

  return open ? (
    <div className={order__small__modal}>
      <div className={order__small__modal__content} ref={ref}>
        {children}
        <PaymentModal className={order__small__pay} />
      </div>
    </div>
  ) : null
}
