import React from "react"
import {
  images__wrapper,
  images__wrapper__title,
  images__wrapper__description,
  images__wrapper__first,
  images__wrapper__second,
  images__wrapper__third,
  images__wrapper__divider,
  images__wrapper__mirror,
  left__elipse,
  right__elipse,
} from "./style/advantages.module.scss"
import Sleep from "./icons/sleepW.svg"
import Yoga from "./icons/yogaW.svg"
import Sneeze from "./icons/sneezeW.svg"
import Divider from "./icons/divider-dark.svg"
import { AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const Advantages = () => {
  return (
    <>
      <div className={right__elipse} />
      <div className={left__elipse} />
      <AnimatedSection className={main__wrapper}>
        <section className={images__wrapper}>
          <h3 className={images__wrapper__title}>
            Szukasz najlepszego, naturalnego i skutecznego preparatu CBD?
          </h3>
          <p className={images__wrapper__description}>
            Zobacz jak regularne stosowanie konopii pomoże Ci w codziennym
            życiu.
          </p>
          <div className={images__wrapper__first}>
            <div>
              <h3>Zredukuj stres i zrelaksuj się</h3>
              <img src={Yoga} alt="yoga" />
              <p>
                We współczesnym świecie szybkie tempo życia generuje nadmiar
                stresu związanego z obowiązkami rodzinnymi oraz pracą.
              </p>
            </div>
          </div>
          <img
            className={images__wrapper__mirror}
            alt="divider_two"
            src={Divider}
          />
          <div className={images__wrapper__second}>
            <div>
              <h3>Wyśpij się jak niemowlę</h3>
              <img src={Sleep} alt="sleepy" />
              <p>
                Nieregularny sen i bezsenność mogą prowadzić do przemęczenia i
                być przyczyną wielu problemów zdrowotnych.
              </p>
            </div>
          </div>
          <img
            className={images__wrapper__divider}
            alt="divider_one"
            src={Divider}
          />
          <div className={images__wrapper__third}>
            <div>
              <h3>Podnieś swoją odporność i popraw regenerację</h3>
              <img src={Sneeze} alt="sleepy" />
              <p>
                Obniżona odporność Twojego organizmu może prowadzić do częstych
                zachorowań w chłodniejszym okresie jesienno-zimowym.
              </p>
            </div>
          </div>
        </section>
      </AnimatedSection>
    </>
  )
}

export default Advantages
