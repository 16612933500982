import React from "react"
import {
  container,
  container__button,
  container__wrapper,
  container__wrapper__item,
  right__elipse,
} from "./style/check.module.scss"
import Micro from "./icons/microscope.svg"
import Guarantee from "./icons/guarantee.svg"
import Protection from "./icons/protection.svg"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const Check: React.FC = (): JSX.Element => {
  return (
    <>
      <div className={right__elipse} />
      <AnimatedSection className={main__wrapper}>
        <section className={container}>
          <h2>Sprawdz jak wyciskamy to co najlepsze z lubelskich konopii</h2>
          <p>
            Możesz sprawdzić badania ostatniej partii oleju, aby dowiedzieć się
            o naszych olejach premium typu full spectrum.
          </p>
          <div className={container__wrapper}>
            <div className={container__wrapper__item}>
              <img src={Micro} alt="microscope" />
              <p>POTWIERDZONE BADANIAMI</p>
            </div>
            <div className={container__wrapper__item}>
              <img src={Guarantee} alt="guarantee" />
              <p>100% NAJLEPSZA JAKOŚĆ</p>
            </div>
            <div className={container__wrapper__item}>
              <img src={Protection} alt="protection" />
              <p>BEZPIECZNY PRODUKT</p>
            </div>
          </div>

          <AnimatedButton className={container__button}>
            Sprawdź aktualne badania
          </AnimatedButton>
        </section>
      </AnimatedSection>
    </>
  )
}

export default Check
