import React, { useRef, useState, useContext, useEffect } from "react"
import { AnimatedButton, Modal } from "@components/shared"
import { Delivery } from "@components/ecommerce"
import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
  useContextState,
} from "@components/contexted"
import { createOrder, initPayment } from "../../ecommerce/Checkout/common"
import Icon from "./icons/Path217.svg"
import {
  content__modal,
  content__modal__buttons,
  content__modal__buttons__btn,
} from "./style/paymentmodal.module.scss"
import PP from "./icons/Group.svg"
import Card from "./icons/Path217.svg"
import OrderForm from "./OrderForm"

interface Props {
  className: symbol
  product: object
}
const PaymentModal: React.FC<Props> = ({ className, product }): JSX.Element => {
  const ref = useRef<HTMLElement | boolean>(false)
  const [state, setState] = useState({ point: {} })
  const [payment, setPayment] = useState(false)
  const { imBusy } = useContextState(AppCtx, ["imBusy"])
  const { dispatch } = useContext(CartCtx)
  const cart = useContext(CartCtx)
  const { shipping } = cart.state
  const customer = useContext(CustomerCtx)
  const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
  const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
    "setOrderFromApi",
    "onOrderApiError",
  ])

  // inpost
  if (typeof window !== 'undefined'){
    if (!window.easyPack) {
      window.easyPackAsyncInit = function () {
        easyPack.init({
          defaultLocale: "pl",
          mapType: "osm",
          searchType: "osm",
          points: {
            types: ["parcel_locker"],
            functions: ["parcel_collect"],
          },
          map: {
            initialTypes: ["parcel_locker"],
            useGeolocation: true,
          },
        })
      }
    }
  }
  
  // useEffect(() => {
  //   if (window.easyPack) {
  //     if (shipping.method_title === "Paczkomat Inpost") {
  //       let container = document.getElementById("easypack-map")
  //       if (container) {
  //         let map = easyPack.mapWidget("easypack-map", (point) => {
  //           setState({
  //             point: { ...point },
  //           })
  //         })
  //       }
  //     }
  //   }
  // }, [shipping.method_title])
  // let map = easyPack.mapWidget("easypack-map", (point) => {
  //   setState({
  //     point: { ...point },
  //   })
  // })

  const clearAndAddNew = (product) => {
    dispatch({
      type: "resetCart",
    })
    dispatch({
      type: "addToCart",
      payload: product,
    })
  }

  // console.log('w', window)
  return (
    <>
      <Modal
        ref={ref}
        close={true}
        bgColor="rgba(0, 0, 0, 0.7)"
        noAnimation={true}
        title={false}
      >
        <form className={content__modal}>
          <OrderForm />
          <Delivery />
          {shipping.method_title === "Paczkomat Inpost" ? (
            <React.Fragment>
              <div id="easypack-map"></div>
              <h3 style={{ textAlign: "left" }}>
                Wybrany paczkomat: {state.point.name}{" "}
              </h3>
            </React.Fragment>
          ) : null}
          <h3>DO PŁATNOŚCI</h3>
          <div className={content__modal__buttons}>
            {/* Przygotowanie zamówienia - stworzenie go w woocomemrce ze statusem oczekujace
            Pobranie jego ID oraz linku przekierowującego do PŁATNOŚCI */}

            <AnimatedButton
              className={content__modal__buttons__btn}
              onClick={() => {
                createOrder({
                  cart: cart.state,
                  customer: customer.state,
                  initLoading: loading,
                  deliveryPoint: state.point,
                })
                  .then((data) => {
                    loaded()
                    setOrderFromApi(data)
                    setPayment(true)
                  })
                  .catch(({ data }) => {
                    loaded()
                    onOrderApiError(data)
                  })
              }}
            >
              Potwierdź zamówienie
            </AnimatedButton>
            {/* Jeśli otrzymaliśmy numer ID, oraz link do płatności mozemy rozpocząć płatność i przekierowanie do płacenia
            jesli nie, nie pokazuj przycisku - tutaj to możesz jakoś ładniej ułożyć ale ogólnie flow musi być takie że 
            1. Robię zamówienie
            2. Uruchamiam płatność jeśli mam zamówienie z API */}

            {cart.state.orderInApi.id ? (
              <AnimatedButton
                className={content__modal__buttons__btn}
                onClick={() => {
                  initPayment({ cart, initLoading: loading })
                    .then((payment) => {
                      console.log("payment", payment)
                      loaded()
                      window.location.href = payment.redirectUri
                    })
                    .catch((payment) => {
                      console.log("err", payment)
                    })
                }}
              >
                Rozpocznij Płatność
              </AnimatedButton>
            ) : null}
          </div>
        </form>
      </Modal>
      <AnimatedButton
        className={className}
        onClick={() => {
          ref.current.toggle(), clearAndAddNew(product), setPayment(false)
        }}
      >
        <img src={Icon} alt="" />
      </AnimatedButton>
    </>
  )
}

export default PaymentModal
