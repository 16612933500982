import React from "react"
import {
  container,
  container__content,
  container__content__form,
  container__content__form__btn,
  container__content__form__checkbox,
} from "./style/newsletter.module.scss"
import Logo from "./icons/theoldhempLOGO.svg"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const Newsletter: React.FC = (): JSX.Element => {
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <img src={Logo} />
        <div className={container__content}>
          <h3>
            Zapisz się do listy aby otrzymać fragment naszego e-booka i być na
            bieżąco z promocjami
          </h3>
          <form className={container__content__form}>
            <input type="email" placeholder="email" />
            <AnimatedButton className={container__content__form__btn}>
              WYŚLIJ
            </AnimatedButton>
            <label
              htmlFor="agree"
              className={container__content__form__checkbox}
            >
              <input type="checkbox" />* Zgoda na przetwarzanie danych
            </label>
          </form>
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Newsletter
