import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  container,
  container__help,
  container__opinions,
  container__opinions__opinion,
  container__opinions__selected,
  container__opinions__selected__avatar,
  container__opinions__selected__button,
  container__opinions__selected__button__next,
} from "./style/opinions.module.scss"
import PahLogo from "./images/logo1.png"
import Icon from "./icons/Union1.svg"
import Next from "./icons/Path10.svg"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
const OpinionsTable = [
  {
    name: "Andrzej Andrzejowski",
    icon: Icon,
    conent:
      "Calling offence six joy feeling. Coming merits and was talent enough far. Sir joy northward sportsmen education. Discovery incommode earnestly no he commanded if.",
  },
  {
    name: "Jakub Jakubowski",
    icon: Icon,
    conent:
      "Sir deficient curiosity instantly. Easy mind life fact with see has bore ten. Parish any chatty can elinor direct for former. Up as meant widow equal an share least.",
  },
  {
    name: "Michał Michałowski",
    icon: Icon,
    conent:
      "Lasted my coming uneasy marked so should. Gravity letters it amongst herself dearest an windows by. Wooded ladies she basket season age her uneasy saw. Discourse unwilling am no described dejection incommode no listening of.",
  },
]

const Opinions: React.FC = (): JSX.Element => {
  const [opinion, setOpinion] = useState(0)

  const ref = useRef<any>(null)

  const getNextOpinion = () => {
    clearTimeout(ref.current)
    if (opinion > 1) {
      setOpinion(0)
    } else {
      setOpinion((prev) => prev + 1)
    }
  }

  useEffect(() => {
    ref.current = setTimeout(() => {
      if (opinion > 1) {
        setOpinion(0)
      } else {
        setOpinion((prev) => prev + 1)
      }
      clearTimeout(ref.current)
    }, 10000)
    return () => {
      clearTimeout(ref.current)
    }
  }, [opinion])

  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <div className={container__help}>
          <h2>
            Zakupem opakowania rodzinnego wspierasz Polską Akcję Humanitarną
            Pajacyk, 10% ceny przekażemy na ten cel.
          </h2>
          <img src={PahLogo} alt="pah-logo" />
        </div>
        <h1>Opinie naszych klientów</h1>
        <div className={container__opinions}>
          {OpinionsTable.map((el, i) => (
            <div
              key={i}
              className={
                i === opinion
                  ? container__opinions__selected
                  : container__opinions__opinion
              }
            >
              <h4>{el.name}</h4>
              <img
                src={el.icon}
                alt=""
                className={container__opinions__selected__avatar}
              />
              <p>{el.conent}</p>
              {i === opinion && (
                <AnimatedButton
                  className={container__opinions__selected__button}
                  onClick={() => getNextOpinion()}
                >
                  Przeczytaj kolejną opinię{" "}
                  <img
                    src={Next}
                    className={container__opinions__selected__button__next}
                  />
                </AnimatedButton>
              )}
            </div>
          ))}
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Opinions
