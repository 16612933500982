import React, { useState } from "react"
import {
  wrapper,
  background,
  orders,
  orders__chosen,
  orders__chosen__buttons,
  orders__chosen__buttons__quantity,
  orders__chosen__buttons__btn,
  orders__chosen__button,
  orders__item,
  orders__chosen__price,
  orders__item__button,
} from "./style/capacities.module.scss"
import Oils from "./images/oils-ex.png"
import Order from "./icons/Path217.svg"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import PaymentModal from "./PaymentModal"
import { main__wrapper } from "../../../styles/typography.module.scss"

const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const Capacities: React.FC = (props): JSX.Element => {
  const [selected, setSelected] = useState<number | null>(null)
  const [state, setState] = useState({
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variation_id: null,
    variant: null,
    sale: false,
    price: "",
    regularPrice: "",
    unit: "PLN",
    name: "",
    slug: "",
    image: null,
  })
  const { products } = props
  let reversedProducts = [].concat(products).reverse()
  const addToCart = (product) => {
    setState({
      product_id: product.productId,
      name: product.name,
      slug: product.slug,
      price: cleanPrice(product.regularPrice),
      regularPrice: cleanPrice(product.regularPrice),
      regularValue: cleanPrice(product.regularPrice),
      value: cleanPrice(product.regularPrice),
      quantity: 1,
      image: product.image,
    })
  }
  const changeQty = (val) => {
    const _number = Number(val)
    if (_number <= 0 || Number.isNaN(_number)) {
      return false
    } else {
      setState({
        ...state,
        quantity: _number,
        value: _number * state.price,
        regularValue: _number * state.regularPrice,
      })
    }
  }

  return (
    <>
      <section className={wrapper}>
        <h2>Różne pojemności dla Ciebie i twojej rodziny.</h2>
        <p>
          Teraz pełna moc oljeków CBD pełne spektru w większych opakowaniach 15
          ml, 30ml i 50 ml. Abyś mógł cieszyć się zdrowiem jak najdłużej.
        </p>
        <img src={Oils} alt="bottles" />
      </section>
      <div className={background} />
      <AnimatedSection className={main__wrapper}>
        <section className={orders}>
          {reversedProducts.map((order, index) => (
            <div
              key={index}
              className={selected === index ? orders__chosen : orders__item}
            >
              <p onClick={() => setSelected(index)} tabIndex={0}>
                {order.name}
              </p>

              {selected === index && (
                <>
                  <div className={orders__chosen__price}>{state.value} zł</div>
                  <div className={orders__chosen__buttons}>
                    <AnimatedButton
                      className={orders__chosen__buttons__btn}
                      onClick={() => {
                        if (state.quantity <= 1) {
                          return false
                        } else {
                          changeQty(state.quantity - 1)
                        }
                      }}
                    >
                      -
                    </AnimatedButton>
                    <div className={orders__chosen__buttons__quantity}>
                      {state.quantity}
                    </div>
                    <AnimatedButton
                      className={orders__chosen__buttons__btn}
                      onClick={() => changeQty(state.quantity + 1)}
                    >
                      +
                    </AnimatedButton>
                  </div>
                </>
              )}
              {selected === index ? (
                <PaymentModal
                  className={orders__chosen__button}
                  product={state}
                />
              ) : (
                <AnimatedButton
                  className={orders__item__button}
                  onClick={() => {
                    setSelected(index), addToCart(order)
                  }}
                >
                  Wybieram ten
                </AnimatedButton>
              )}
            </div>
          ))}
        </section>
      </AnimatedSection>
    </>
  )
}

export default Capacities
