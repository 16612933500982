import React from "react"
import {
  content__modal__double,
  content__modal__double__single,
} from "./style/paymentmodal.module.scss"
import Divide1 from "./images/divideDark1.png"
const Invoice: React.FC = (): JSX.Element => {
  return (
    <>
      <img src={Divide1} />
      <h3>Faktura na firmę?</h3>

      <label>NIP</label>
      <input placeholder="999 999 99 99" />

      <label>NAZWA FIRMY</label>
      <input placeholder="EXAMPLE COMAPANY NAME" />

      <label>IMIĘ I NAZWISKO</label>
      <input placeholder="Joanna Nowak" />

      <label>ADRES</label>
      <input placeholder="497 Evergreen Rd." />

      <label>NIP</label>
      <input placeholder="999 999 99 99" />

      <div className={content__modal__double}>
        <div className={content__modal__double__single}>
          <label htmlFor="Number">NR BUDYNKU</label>
          <input placeholder="Roseville" />
        </div>

        <div className={content__modal__double__single}>
          <label>NR MIESZKANIA</label>
          <input placeholder="95673" />
        </div>
      </div>

      <div className={content__modal__double}>
        <div className={content__modal__double__single}>
          <label>Miasto</label>
          <input placeholder="Roseville" />
        </div>

        <div className={content__modal__double__single}>
          <label>KOD POCZTOWY</label>
          <input placeholder="95673" />
        </div>
      </div>
    </>
  )
}

export default Invoice
