import React from "react"

import { Layout } from "@components/layout"
import {
  Hero,
  Order,
  Advantages,
  Blog,
  Check,
  Capacities,
  Opinions,
  Forest,
  Ebook,
  Warranty,
  About,
  Newsletter,
} from "@components/standard"

const IndexPage = ({ pageContext, location }) => {
  const { page, productsWithDetails } = pageContext

  return (
    <Layout {...page}>
      <Hero />
      <Order products={productsWithDetails} />
      <Advantages />
      <Blog />
      <Check />
      <Capacities products={productsWithDetails} />
      <Opinions />
      <Forest />
      <Ebook />
      <Warranty />
      <Newsletter />
      <About />
    </Layout>
  )
}

export default IndexPage
